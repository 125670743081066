<script lang="ts" setup>
  import RouletteSlider from '~/components/sliders/roulette/RouletteSlider.vue';
  import { getNoveltyMovies } from '~/services/modules/movies.service';
  import RouletteSkeleton from '~/components/sliders/roulette/RouletteSkeleton.vue';
  import type { TBrowseTitle } from '~/types/common';

  defineProps<TBrowseTitle>();

  const { data, isFetching, suspense } = getNoveltyMovies({ params: { announcement: true } });

  onServerPrefetch(async () => await suspense());
</script>

<template>
  <v-wrapper
    v-if="(data ?? []).length"
    section
    :use-low-priority-title="isCatalog"
    class="upcoming full-width"
    title="Скоро на FLEX"
    remove-padding
    :link="{ name: 'Смотреть все', route: { name: 'upcoming' } }"
  >
    <roulette-slider
      appendClass="upcoming__slider content-right"
      :items="data ?? []"
      :is-fetching="isFetching"
      skeleton
      hide-cards-status
    />
  </v-wrapper>
</template>

<style lang="scss" scoped>
  .upcoming {
    padding: 56px 0 0;
    //background: linear-gradient(243deg, rgba(53, 19, 54, 0.48) 0%, rgba(42, 44, 50, 0.6) 71%);
    background: linear-gradient(243deg, rgba(53, 19, 54, 0.48) 0%, rgb(42 44 50 / 25%) 71%);
    box-shadow: 294px 294px 294px;
    @media (max-width: $retina) {
      padding: 24px 0;
    }
  }
</style>
